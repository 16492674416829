import React, {useEffect, useState} from 'react';
import {Card, Typography, Checkbox, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {connect} from 'react-redux'
import { addFilter, removeFilter, resetFilters } from '../../actions/productInfoActions'

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: 20,
        background: 'linear-gradient(145deg, #495a65, #576b78)',
        boxShadow: '9px 9px 18px #3a4750, -9px -9px 18px #688190',
        minWidth : 200,
        minHeight : 200,
        padding : 20,
        color : '#ffffff',
        marginLeft : 10,
        marginRight : 10,
        marginBottom : 20
    },
    bullet: {
        display: 'inline-block',
        margin: '0 10px 0 0',
        transform: 'scale(5)',
    },
}))


function Filters(props) {
    const {categories, addFilter, removeFilter, resetFilters, brands} = props;
    const classes = useStyles()
    const [filters, setFilters] = useState({});

    const manageFilters = (filterId, filterChecked) => {
        if (filterChecked) {
            addFilter(filterId)
        } else {
            removeFilter(filterId)
        }
    }

    useEffect(()=>{
        console.log(filters)
    }, [filters])



    return (
        <Card className={classes.card}>
            <Typography variant={'h6'}>
                Filters
            </Typography>
            <hr/>
            <Typography variant={'body1'}>
                Type of product
            </Typography>
            {categories && categories.map((category) =>
                <Typography key={category.cat_id} component={'p'} variant={"body1"}>
                    <Checkbox
                        id={category.cat_name}
                        checked={category.checked}
                        onChange={(e) => manageFilters(e.target.value, e.target.checked)}
                        inputProps={{ 'aria-label': 'GPU' }}
                        value={category.cat_id}
                    />{category.cat_name}
                </Typography>
            )}
            <hr/>
            <Typography variant={'body1'}>
                Brand
            </Typography>
            <Typography variant={'body1'}>
                {brands && brands.map((brand)=>
                    <Typography key={brand} component={'p'} variant={"body1"}>
                        <Checkbox
                            id={brand}
                            checked={brand.checked}
                            onChange={(e) => manageFilters(e.target.value, e.target.checked)}
                            inputProps={{ 'aria-label': 'BRAND' }}
                            value={brand}
                        />{brand}
                    </Typography>
                )}
            </Typography>
        </Card>
    );
}

function mapDispatchToProps(dispatch){
    return {
        removeFilter: (filterId) => dispatch(removeFilter(filterId)),
        addFilter: (filterId) => dispatch(addFilter(filterId)),
        resetFilters: () => dispatch(resetFilters())
    }
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);