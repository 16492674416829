import * as types from './actionsCatalogue';


export const addFilter = (filterId) => {
    return({
        type: types.ADD_FILTER,
        payload : filterId
    })
}

export const removeFilter = (filterId) => {
    return({
        type: types.REMOVE_FILTER,
        payload : filterId
    })
}

export const resetFilters = () => {
    return({
        type: types.CLEAR_FILTERS
    })
}
