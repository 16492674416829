import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import theme from './theme';
import {Provider} from "react-redux";
import store from './store'
import {Container} from "@material-ui/core";
import Body from './components/layout/body';

const useStyles = makeStyles(theme => ({
    root: {
        color: '#fffff'
    }
}))


const App = () => {
    const classes = useStyles()
        return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <Container maxWidth='xl' className={classes.root} disableGutters={true}>
                <Body/>
                {/*<Footer/>*/}
            </Container>
        </Provider>
    </ThemeProvider>
)}
export default App