import axios from 'axios'

const dynoWaker = () =>{
    console.log('waking up dyno')
    axios.get('https://product-snatcher-eu.herokuapp.com/')
        .then((result) => {
            console.log(result)
        })
        .catch((e)=> console.error(e))
}

export default dynoWaker;