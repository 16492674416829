import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button,
    Container,
    LinearProgress
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        color: '#ffffff',
        flexGrow : 1,
        boxShadow : '5px 5px 10px #8b99a2, -5px -5px 10px #fdffff',
        marginBottom : 10
    },
    logoItem : {
        textAlign: 'center',
        paddingTop : theme.spacing(2),
        marginBottom : theme.spacing(2)
    },
    logo : {
        borderLeft : '#ffffff',
        borderStyle : 'solid',
    }
}))


const Header = (props) => {
    const classes = useStyles()
    const {loading} = props;

    return (
        <div className={classes.root}>
            {loading === 1 && <LinearProgress />}
            <Grid container alignContent={'space-between'}>
                <Grid item xs={12} className={classes.logoItem}>
                    <Typography variant={'h2'} > PRODUCT SNATCHER </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
export default Header