import React from 'react';
import {
    Card,
    Grid,
    Typography,
    CardActions,
    Button,
    Link
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: 20,
        background: 'linear-gradient(145deg, #495a65, #576b78)',
        boxShadow: '9px 9px 18px #3a4750, -9px -9px 18px #688190',
        minWidth : 200,
        minHeight : 200,
        padding : 20,
        color : '#ffffff',
        marginLeft : 10,
        marginRight : 10
    },
    bullet: {
        display: 'inline-block',
        transform: 'scale(5)',
    },
}))

function ItemCard(props) {
    const classes = useStyles()
    const product = props.product
    const {name, description, price, instock, brand, lastchecked, product_link, eshop} = product;
    const bull = <span className={classes.bullet} style={{color : instock ? '#33cc00' : '#990000'}}>•</span>;
    let lastUpdate = new Date(lastchecked).toUTCString().split(' GMT')[0];


    return (
        <Card className={classes.card}>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={12}>
                    {bull}
                </Grid>
            </Grid>
            <Typography variant={'h6'}>
                {name}
            </Typography>
            <hr/>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                    <Typography variant={'body2'}>
                        {eshop}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" size="small" color={instock ? "primary" : "secondary"} disabled={!instock}>
                        <Link href={instock ? product_link : '#'}  target="_blank" underline="none" style={{color : "#ffffff"}}>
                            {instock ? 'Grab It !' : 'Out Of Stock'}
                        </Link>
                    </Button>
                </Grid>
            </Grid>
        <hr/>
            {lastUpdate} <hr/>Price : {price}€
        </Card>
    );
}

export default ItemCard;