import * as types from '../actions/actionsCatalogue'
import initialState from './initialState'

const removeFilter = (filterList, id) => {
    return filterList.filter(function(el){
        return el !== id
    })
}

export default function filters(state = initialState, action) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {

        case types.ADD_FILTER :
            let newFilterListAdd = [...state, action.payload];
            return newFilterListAdd;

        case types.REMOVE_FILTER :
            let newFilterListRemove = removeFilter(state, action.payload);
            return newFilterListRemove;


        // Do something here based on the different types of actions
        default:
            return state
    }
}