import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
    palette: {
        background: {
            default: '#516470'
        },
        primary: { 500: '#33cc00' },
        secondary: { main: '#990000'}
    },

})
export default theme