import React, {useEffect, useState} from 'react';
import {CircularProgress, Grid} from "@material-ui/core";
import Filters from "../functions/filters";
import ItemCard from "../itemCard";
import Header from "./header";
import {gql, useQuery, useSubscription} from '@apollo/client';
import dynoWaker from '../../connectors/dynoWaker';
import {connect} from 'react-redux'

const GET_PRODUCTS = gql`
      subscription products {
          products(order_by: {instock: desc, eshop: asc, name: asc}) {
            description
            eshop
            id
            instock
            lastchecked
            name
            price
            product_link
            subcat_id
            brand
          }
        }`

const GET_CATS = gql`
    query getCats {
      categories(where: {cat_id: {_lte: 1}}) {
        cat_name
        cat_id
      }
    }`

const GET_BRANDS = gql`
    query getBrands {
      products(distinct_on: brand) {
        brand
      }
    }`

function Body(props) {
    const { filters } = props;
    const { loading, error, data } = useSubscription(GET_PRODUCTS);
    const categories = useQuery(GET_CATS)
    const brands = useQuery(GET_BRANDS)
    const [brandsList, setBrandsList] = useState([]);
    const [products, setProducts] = useState([]);
    const [cats, setCats] = useState([]);

    useEffect(()=>{
        dynoWaker();
    },[])


    useEffect(()=>{
        if (filters && (data && data.products)) {
            setProducts(data.products.filter(p => filters.includes(p.brand)))
        }
    }, [data, filters])

    useEffect(()=>{
        let distinctBrandsList = brands?.data?.products?.map((brand) => {
            return brand.brand
        })
        setBrandsList(distinctBrandsList)
    },[brands])


    return (
        <>
        <Header loading={loading}/>
        <Grid container style={{marginTop: 50}}>
            <Grid item xs={12} md={2}>
                <Filters brands={brandsList} categories={categories &&  categories.data?.categories}/>
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid container spacing={3} direction="row" >
                    {
                        !products?
                            <><Grid item xs={12} style={{color : '#ffffff'}}>
                                <CircularProgress/>
                            </Grid></> :
                            products && products.map(item =>
                            <Grid key={item.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <ItemCard product={item}/>
                            </Grid>

                        )
                    }
                </Grid>

            </Grid>
        </Grid>
        </>
    );
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Body);