import { HttpLink } from "apollo-link-http"
import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { WebSocketLink} from "@apollo/client/link/ws";

export default new ApolloClient({
    cache: new InMemoryCache(),
    link: new WebSocketLink({
        uri: 'wss://product-snatcher-europe.herokuapp.com/v1/graphql',
        options: {
            reconnect: true
        }
    }),
})